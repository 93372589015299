export const slider =()=> {

    const swiperSlider = new Swiper('.slider-swiper', {
        // loop: true,
        autoHeight: true,
        spaceBetween: 40,
        speed: 1000,
        navigation: {
            nextEl: '.slider-next',
            prevEl: '.slider-prev',
        },
        scrollbar: {
            el: '.slider-scrollbar',
            dragSize: '57px',
        },
    });

}