import {vars} from "../vars.js";

export const mobileMenu = ()=> {

    const burger = document.querySelector('.burger');

    burger?.addEventListener('click',()=> {
        vars.bodySite?.classList.toggle('overflow-hidden');
        vars.mobileMenu?.classList.toggle('active');
        vars.burger?.classList.toggle('active');
    })

}