import {smoothScrollTo} from "../smooth-scroll.js";
import {vars} from "../vars.js";

export const anchorScroll = ()=> {
    const anchorBtns = document.querySelectorAll('.anchor-scroll-js')
    const anchorMenusBtns = document.querySelectorAll('.anchor-scroll-menu-js')
    const headerHeight = vars.header.getBoundingClientRect().height

    if (window.location.hash) {
        smoothScrollTo(0,2)
        setTimeout(()=> {
            try {
                const hashElement = document.querySelector(window.location.hash)
                const elementOffset = hashElement?.getBoundingClientRect().top - headerHeight
                if (elementOffset < 0) {
                    smoothScrollTo(0,2)
                } else {
                        smoothScrollTo(elementOffset,2)
                }
                history.pushState("", "", `${location.pathname}${location.search}`);
            } catch (error) {
                console.log(error)
            }
        },1200)
    }

    anchorBtns?.forEach((item)=> {

        item.addEventListener('click',(e)=> {
            try {
                const itemHref = item.getAttribute('href')
                const itemTarget = document.querySelector(itemHref)

                //close mobile

                vars.bodySite?.classList.remove('overflow-hidden')
                vars.header?.classList.remove('active')
                vars.mobileMenu?.classList.remove('active')
                vars.burger?.classList.remove('active')

                //close mobile

                if (itemTarget == null) {
                    const url = window.location.href;
                    window.location.href = window.location.origin + '/'+itemHref

                } else {
                    const itemOffset = itemTarget?.getBoundingClientRect().top - headerHeight
                    smoothScrollTo(itemOffset,2)
                }
                e.preventDefault()

            } catch (error) {
                console.log(error)
            }
        })
    })

    anchorMenusBtns?.forEach((item)=> {

        item.addEventListener('click',(e)=> {
            try {
                const itemLink = item.querySelector('a')
                const itemHref = itemLink?.getAttribute('href')
                const itemTarget = document.querySelector(itemHref)

                //close mobile

                vars.bodySite?.classList.remove('overflow-hidden')
                vars.header?.classList.remove('active')
                vars.mobileMenu?.classList.remove('active')
                vars.burger?.classList.remove('active')

                //close mobile

                if (itemTarget == null) {
                    const url = window.location.href;
                    window.location.href = window.location.origin + '/'+itemHref

                } else {
                    const itemOffset = itemTarget?.getBoundingClientRect().top - headerHeight
                    smoothScrollTo(itemOffset,2)
                }
                e.preventDefault()

            } catch (error) {
                console.log(error)
            }
        })
    })

}