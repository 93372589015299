import {mobileMenu} from "./modules/mobile-menu.js";
import {slider} from "./modules/slider.js";
import {anchorScroll} from "./modules/anchor-scroll.js";
import {contactForm} from "./modules/contact-form.js";

document.addEventListener("DOMContentLoaded", function (event) {

    mobileMenu();

    slider();

    contactForm();

    anchorScroll();
});
