import {smoothScrollTo} from "../smooth-scroll.js";
import {vars} from "../vars.js";

export const contactForm = ()=> {
    const formsBlock = document.querySelectorAll('.forms-block-js');

    formsBlock?.forEach(item => {
        const thanksPop = item.querySelector('.forms-thanks-js');
        const formWrapper = item.querySelector('.forms-wrapper-js');
        const form = formWrapper?.querySelector('form');
        const decoration = item.querySelector('.contact__decoration');
        form?.addEventListener('wpcf7mailsent', () => {
            thanksPop?.classList.add('active');
            formWrapper?.classList.add('hide');
            form?.classList.add('opacity-0');
            decoration?.classList.add('active');
            document.querySelector('.contact')?.classList.add('active');

            const headerHeight = vars.header.getBoundingClientRect().height
            const hashElement = document.getElementById('contact')
            const elementOffset = hashElement?.getBoundingClientRect().top - headerHeight
            console.log(elementOffset)
            smoothScrollTo(elementOffset,2)
        })

        form?.addEventListener('submit', function(event) {
            const button = form.querySelector('input[type="submit"]');
            button.disabled = true;
            //button.value = "Sending...";
        });
    })
    document.addEventListener('wpcf7submit', function(event) {
        const buttons = document.querySelectorAll('.wpcf7-submit[disabled]');
        buttons.forEach(function(button) {
            button.disabled = false;
            //button.value = "Send message";
        });
    });
}